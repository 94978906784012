export const processBasketForAnalytics = (basket, currency) => {
  const itemList = basket.map((product) => {
    return {
      item_name: product.title,
      // "item_category": "cat_placeholder"
      ...(product.selectedPackage && { item_variant: product.selectedPackage }),
      price: currency === "RSD" ? product.priceSrb : product.priceOther,
      quantity: product.quantity,
    };
  });

  return {
    currency: currency,
    value: getTotalBasketPriceWithoutPostage(basket, currency),
    items: itemList,
  };
};

export const getTotalBasketPriceWithoutPostage = (basket, currency) => {
  if (currency === "RSD") {
    return parseFloat(basket.reduce((acc, curr) => acc + curr.quantity * parseFloat(curr.priceSrb), 0)).toFixed(2);
  } else {
    return parseFloat(basket.reduce((acc, curr) => acc + curr.quantity * parseFloat(curr.priceOther), 0)).toFixed(2);
  }
};

export const getCountryCurrency = (country) => {
  if (country === "Srbija") return "RSD";
  else return "EUR";
};

export const processOrderForAnalytics = (order, id) => {
  const analyticsBasket = processBasketForAnalytics(order.basket, getCountryCurrency(order.country));

  return {
    ...analyticsBasket,
    transaction_id: id,
    shipping: order.totalPrice - analyticsBasket.value,
  };
};
